
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
















































.header-sticky {
  background-color: $white;
}

.header-sticky__items {
  display: flex;
  gap: 2px;
  margin-top: $spacing * 0.5;

  & + & {
    margin-top: 2px;
  }
}

.header-sticky__item {
  @extend %text-center;
  @include bg-color;

  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: $spacing * 0.75 0;
  font-family: $ff-alt;

  &.center {
    @extend %text-center;

    align-items: center;
  }

  &.is-midnight-darkest {
    color: $white;
  }
}

.header-sticky__item__headline {
  @extend %text-uppercase;

  font-size: 1.1rem;
  letter-spacing: 1px;
}

.header-sticky__item__text {
  font-size: 2.1rem;
  font-weight: 700;
}
