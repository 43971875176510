
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































































































































.picture__image {
  filter: url('#blur');
  will-change: content;

  .is-loaded & {
    filter: none;
  }
}
