/* stylelint-disable declaration-no-important */
.voo-header,
[class*='voo-header--'] {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100vw;
  transition: background 0.1s, box-shadow 0.4s;

  // [data-scheme='transparent'] & {
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     height: 200%;
  //     background: // https://larsenwork.com/easing-gradients/#editor
  //       linear-gradient(
  //       to bottom,
  //       hsla(0, 0%, 0%, 0.3) 0%,
  //       hsla(0, 0%, 0%, 0.296) 8.1%,
  //       hsla(0, 0%, 0%, 0.285) 15.5%,
  //       hsla(0, 0%, 0%, 0.269) 22.5%,
  //       hsla(0, 0%, 0%, 0.247) 29%,
  //       hsla(0, 0%, 0%, 0.222) 35.3%,
  //       hsla(0, 0%, 0%, 0.194) 41.2%,
  //       hsla(0, 0%, 0%, 0.165) 47.1%,
  //       hsla(0, 0%, 0%, 0.135) 52.9%,
  //       hsla(0, 0%, 0%, 0.106) 58.8%,
  //       hsla(0, 0%, 0%, 0.078) 64.7%,
  //       hsla(0, 0%, 0%, 0.053) 71%,
  //       hsla(0, 0%, 0%, 0.031) 77.5%,
  //       hsla(0, 0%, 0%, 0.015) 84.5%,
  //       hsla(0, 0%, 0%, 0.004) 91.9%,
  //       hsla(0, 0%, 0%, 0) 100%
  //     );
  //   }
  // }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @include mq($until: 'nav') {
    height: 100vh;
    background-color: $c-light;
    clip: rect(0, 100vw, 50px, 0);

    // Mobile Landscape 🪟
    @media screen and (max-height: 600px) {
      &.menu-open {
        overflow-y: auto;
      }
    }

    [data-scheme='transparent'] & {
      background: transparent;

      &:after {
        content: '';
        @include get-all-space;
        top: 5rem;
        background: white;
        z-index: -1;
      }
    }
    &.has-submenu {
      clip: rect(0, 100vw, 91px, 0);

      [data-scheme='transparent'] & {
        &:not(.menu-open) {
          &:after {
            top: 9.1rem;
          }
        }
      }
    }
  }

  &.sticky {
    background-color: $white;
    // box-shadow: 0;
    transform: translateY(-5rem);
    transition: transform 0.4s, background 0.1s, box-shadow 0.4s;

    // &::after {
    //   position: absolute;
    //   z-index: -1;
    //   display: block;
    //   content: '';
    //   width: 100%;
    //   height: 40px;
    // }

    // [data-template='blog'] &,
    // [data-template='employee-advocacy'] &,
    [data-scheme='dark'] & {
      @include mq(l) {
        background-color: $c-blue-dark !important;
      }
    }

    &.is-minimal,
    [data-scheme='transparent'] & {
      background: transparent;
    }

    @include mq(l) {
      transform: translateY(-100%);
    }
  }

  &.up {
    transform: translateY(0);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

    [data-scheme='transparent'] & {
      box-shadow: none;
    }
  }

  // TODO Needed?
  // .is-minimal &,
  // [data-scheme='hidden'] & {
  //   position: absolute !important;
  //   background-color: transparent;
  //   transform: translateY(0);
  // }

  // @include mq('nav') {
  //   position: absolute;
  // }
}

.voo-header__top-outer {
  background-color: $c-blue-dark;

  // [data-scheme='transparent'] & {
  //   background-color: transparent;
  //   border-bottom: 1px solid transparentize($color: $white, $amount: 0.5);
  // }
}

.voo-header__top {
  z-index: 5;
  display: flex;
  color: $c-light;

  > * {
    height: 5rem;
    line-height: 5rem;
  }

  @include mq($until: 'xl') {
    padding-right: 0 !important;
    padding-left: $spacing * 1.5 !important;
  }

  @include mq('xxxl') {
    width: col(12);
    margin: 0 auto;
  }
}

.voo-header__top__switcher {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-basis: 100%;

  .is-minimal & {
    justify-content: flex-end;
    flex-basis: 100% !important;
  }

  @include mq($until: 'nav') {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: $spacing;
    background-color: $c-blue-dark;
    transform: translateY(100%);
  }

  @include mq('nav') {
    flex-basis: 75%;
  }

  @include mq(xl) {
    flex-basis: 82%;
  }

  // Mobile Landscape 🪟
  @media screen and (max-height: 600px) {
    z-index: 1;
  }
}

.voo-header__top-inner {
  z-index: 1;
  // DEBUG: IE
  display: flex;
  // justify-content: flex-end;
  width: 100%;
  height: 5rem;
  margin-left: auto;
  color: $c-light;
  background-color: $c-blue-dark;

  > * {
    height: 5rem;
    line-height: 5rem;
  }

  @include mq($until: 'nav') {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  @include mq('nav') {
    // DEBUG: IE
    // display: flex;
    // justify-content: flex-end;
    flex-basis: 25%;
  }

  @include mq(xl) {
    flex-basis: 18%;
  }
}

.voo-header__top__universe {
  z-index: 1;
  flex-shrink: 0;
  background-color: $c-blue-dark;

  [data-scheme='transparent'] & {
    background-color: transparent;
    // border-bottom: 1px solid $white;
  }
}

.voo-header__top__business-number {
  margin: 0 $spacing * 0.75;

  a {
    @extend %fw-bold;

    color: $white;
    font-family: $ff-alt;
    font-size: 1.6rem;
    text-decoration: none;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    fill: $c-pink-medium;
    vertical-align: middle;
  }

  &:hover {
    a {
      color: $c-pink-medium;
    }
  }

  @include mq($until: 'nav') {
    position: absolute;
    top: 0;
    right: 12rem;
  }

  @include mq($until: 'm') {
    right: 6rem;
  }

  @include mq('l') {
    font-size: 1.8rem;
  }
}

.voo-header__top__lang {
  // margin-right: auto;

  // DEBUG: IE
  @include mq($until: 'nav') {
    float: left;
  }

  @include mq(m, l) {
    margin-left: $spacing * 3.5;
  }

  @include mq('nav') {
    margin-left: auto;
  }
}

.voo-header__top__help {
  flex-shrink: 0;
  background-color: $c-bunting;
  transition: background-color 0.2s $ease-out-quad;

  &:hover {
    background-color: lighten($c-bunting, 10%);
  }
}

.voo-header__top__profile {
  background-color: $c-pink-medium;
  transition: background-color 0.2s $ease-out-quad;

  &:hover {
    background-color: lighten($c-pink-medium, 10%);
  }

  &.is-connected {
    background-color: $c-blue-medium;
  }
}

.voo-header__nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: col(12);
  min-height: $spacing * 2.5;
  margin: 0 auto;

  @include mq($until: m) {
    padding-right: 0;
    padding-left: $spacing / 2;
  }

  @include mq('nav') {
    flex-wrap: initial;
    justify-content: initial;
    padding-top: $spacing;
    padding-bottom: $spacing / 2;
    min-height: $spacing * 3;
  }
}

.voo-header__nav__logo {
  left: $spacing;
  line-height: 0;

  .icon {
    width: 7rem;
    height: 3rem;
  }

  @include mq($until: 'nav') {
    // margin-top: 1rem;

    [data-scheme='hidden'] & {
      margin-top: 0;
    }
  }

  @include mq('m') {
    left: $spacing * 3.5;
  }

  @include mq('nav') {
    .icon {
      width: 8rem;
      height: 4rem;
    }
  }

  @include mq('xl') {
    position: absolute;
    top: 50%;
    left: $spacing * 6;
    transform: translateY(-50%);
  }
}

.voo-header__nav__menu {
  margin: 0 0 0 auto;
  font-family: $ff-alt;

  [data-scheme='hidden'] & {
    display: none;
  }

  @include mq($until: 'nav') {
    order: 3;
    width: 100%;
  }

  @include mq('nav') {
    margin: 0 auto;
  }

  // Mobile Landscape 🪟
  @media screen and (max-height: 600px) {
    padding-bottom: 11.5rem;
  }
}

.voo-header__nav__btn {
  position: fixed;
  z-index: 0;
  bottom: $spacing * 2.5;
  left: $spacing;
  order: 4;
  width: 100vw;
  margin-right: -$spacing;
  margin-left: -$spacing;

  @include mq('m') {
    left: $spacing * 3.5;
    margin-right: -$spacing * 3.5;
    margin-left: -$spacing * 3.5;
  }

  @include mq('nav') {
    display: none;
  }

  // Mobile Landscape 🪟
  @media screen and (max-height: 600px) {
    bottom: 0;
    padding-bottom: $spacing * 2.5;
    background-color: white;
  }
}

// Trigger & Close
.voo-header__top__close {
  position: relative;
  width: 5rem;
  height: 5rem;
  margin-left: auto;
  cursor: pointer;

  // DEBUG: IE
  @include mq($until: 'nav') {
    float: right;
  }

  @include mq('m', 'nav') {
    margin-right: $spacing * 3.5;
  }

  @include mq('nav') {
    display: none;
  }
}

.voo-header__top__logo {
  margin: 0 $spacing / 2;
  fill: $white;

  @include mq(l) {
    display: none;
  }
}

.voo-header__top__close__icon {
  @include center-xy;

  fill: $c-light;
  pointer-events: none;
}

.voo-header__nav__trigger {
  position: relative;
  width: 5rem;
  height: 5rem;
  cursor: pointer;

  .is-minimal & {
    display: none;
  }

  @include mq('nav') {
    display: none;
  }
}

.voo-header__nav__trigger__icon {
  @include center-xy;

  fill: $c-blue-dark;
  pointer-events: none;

  [data-scheme='transparent'] & {
    fill: $white;
  }
}

.voo-header-cart {
  position: relative;
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 2.2rem 0 1.6rem;

  svg {
    width: 2.2rem;
    height: 2rem;
  }

  &::after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: $c-pink-medium;
    border-radius: 50%;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
}

// Basket Mobile
.voo-header__nav__cart {
  margin-left: auto;
  transform: translateX(9px);

  @include mq('nav') {
    display: none;
  }
}

// Basket Desktop
.voo-header__top__cart {
  svg {
    fill: white;
  }

  @include mq($until: 'nav') {
    display: none;
  }
}

.voo-wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: $wrapper-spacing-s;
  padding-left: $wrapper-spacing-s;
  max-width: $wrapper-width-xl;

  @include mq('m') {
    padding-right: $wrapper-spacing-m;
    padding-left: $wrapper-spacing-m;
  }

  @include mq('xl') {
    padding-right: $wrapper-spacing-xl;
    padding-left: $wrapper-spacing-xl;
  }
}
