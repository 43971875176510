
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































$full-card-width: 380px;
$full-card-width-s: 300px;
$padding: 3rem 2rem 2rem;

.product-card,
[class*='product-card--'] {
  position: relative;
  width: $full-card-width-s;

  @include mq(l) {
    width: $full-card-width;
  }
}

.product-card__inner {
  @extend %box-shadow;

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $c-light;
  border-radius: 2rem;
}

.product-card__main {
  position: relative;
  z-index: 10;
  flex: 1 auto;
  padding: 3rem 3rem 1.5rem;
  border-radius: 2rem 2rem 0 0;
}

.product-card__details {
  position: relative;
  padding: 1.5rem 3rem;

  .mobile-card & {
    padding-top: 1rem;

    ::v-deep .price {
      display: none;
    }
  }

  .mobile-card.no-advantage & {
    padding-top: 3rem;
  }
}

.product-card__details__inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0;
  border-radius: 0 0 2rem 2rem;
}

.product-card__details__cta {
  position: relative;
  margin-bottom: $spacing * -0.75;
  padding-top: $spacing * 0.5;
  padding-bottom: $spacing * 0.5;
  background-color: $white;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: $spacing * 1.5;
    height: 100%;
    background-color: $white;
  }

  &::before {
    left: $spacing * -1.4;
  }

  &::after {
    right: $spacing * -1.4;
  }

  @include mq(l) {
    display: none;
  }
}

::v-deep {
  .btn.cta__order {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;

    .is-zuny & {
      background-color: $c-midnight-darkest;
    }
  }

  .btn__link__title {
    .is-zuny & {
      font-family: $ff-zuny;
      font-weight: 900;
    }
  }

  .selection {
    min-height: 7rem;
    margin-bottom: 0;
  }
}
