
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































































































































.share {
  display: flex;
  align-items: center;
}

.share__label {
  @extend %fw-bold;

  color: rgba($white, 0.7);
  font-size: 1.6rem;
  line-height: 1em;
  white-space: nowrap;

  .share.is-light & {
    color: $white;
  }
}

.share__list {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
}

.share__item {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;

  & + & {
    margin-left: $spacing / 2;
  }
}

.share__item__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.share__item__link__icon {
  width: 100%;
  fill: rgba($white, 0.4);
  transition: fill 0.2s ease-out;

  .share__item:hover & {
    fill: $white;
  }

  .share.is-light .share__item:hover & {
    fill: $white;
  }
}

.share__item__feedback {
  @extend %text-uppercase;

  position: absolute;
  top: 0;
  padding: $spacing / 4 $spacing / 2;
  color: $c-blue-dark;
  font-size: 1.1rem;
  font-weight: 900;
  white-space: nowrap;
  background-color: rgba($white, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;

  .is-copied & {
    // opacity: 1;
    // transform: translateY(-120%);
    animation: show 1s ease-out both;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    transform: translateY(-120%);
  }

  100% {
    opacity: 0;
    transform: translateY(-140%);
  }
}
