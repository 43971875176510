
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































.cta {
  max-width: 50rem;
  margin: $spacing * 2.5 auto 0;
}

.cta-inner {
  @extend %box-shadow;

  display: flex;
  align-items: center;
  padding: 2rem 3rem;
  gap: 2rem;
  border-radius: 1rem;
}

.cta__icon {
  fill: $c-pink-medium;
}
