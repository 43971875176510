
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































/* vue:scoped */
.is-error {
  position: relative;
  border: 1px dotted $c-dev-error;
  padding: 0.5em 1em;

  &::after {
    content: 'not found';
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 0.5em;
    background-color: $c-dev-error;
    color: $white;
    font-size: 9px;
  }
}
