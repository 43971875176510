
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































































































































































































































































































































































$content-padding-mobile: 2rem;

.content__inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: $content-padding-mobile;

  @include mq(l) {
    min-height: revert;
    padding: 3rem 4rem 4rem 5rem;
  }
}

.content__close {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 2rem;
  padding: 0;
  background: transparent;
  border-width: 0;

  svg {
    stroke: $white;
    fill: none;
    width: 100%;
    height: 100%;
  }

  .content__close__mobile {
    display: block;

    @include mq(l) {
      display: none;
    }
  }

  .content__close__desktop {
    display: none;
  }

  @include mq(l) {
    position: absolute;
    z-index: 1;
    top: 3.3rem;
    right: 3.3rem;
    margin-bottom: 0;

    .content__close__desktop {
      fill: currentColor;
      stroke: none;
      display: block;
    }
  }
}

.content__title {
  margin: 0 0 1.5rem;

  // & + .content__tag-list {
  //   margin-top: -1.5rem;
  // }
}

.content__title-outer {
  display: flex;
  align-items: center;
  gap: $spacing;
}

.subtitles-pictograms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.5rem;

  @include mq(l) {
    justify-content: flex-start;
  }
}

.content__tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.content__tag-list__item {
  // prettier-ignore
  @include fluid(font-size, (s: 9px, l: 15px));

  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.content__visual-container {
  position: relative;
  margin-bottom: 3rem;

  @include mq(l) {
    grid-area: visual;
    margin-bottom: 4.5rem;
  }
}

.content__visual,
.content__iframe {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 1rem;
  aspect-ratio: 640 / 360;
  object-fit: cover;
}

.content__label {
  // prettier-ignore
  @include fluid(font-size, (s: 9px, l: 18px));

  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 0.5rem 3rem;
  font-weight: 500;
  line-height: 1.3;
  transform: translate(-50%, 50%);
  background-color: $c-pink-medium;
  border-radius: 5px;
}

.content__cta {
  @include mq(l) {
    display: flex;
    align-items: flex-end;
    grid-area: cta;
  }
}

.content__cta__inner {
  display: flex;
  align-items: center;
  gap: $spacing;
  margin: 0 (-$content-padding-mobile) 3rem;
  padding: $content-padding-mobile;
  background: rgba($black, 0.03);
  box-shadow: 0 0 15px rgba($black, 0.2);

  @include mq(l) {
    width: 100%;
    margin: 0;
    padding: 4rem 8rem;
    border-radius: 1rem;
  }
}

.content__cta__offer {
  @extend %text-center;
}

.content__cta__offer-caption {
  // prettier-ignore
  @include fluid(font-size, (s: 12px, l: 18px));

  // prettier-ignore
  @include fluid(margin-bottom, (s: 8px, l: 12px));

  padding: 0 1rem;

  @include mq(l) {
    padding: 0;
  }
}

.content__cta__offer-logo {
  // prettier-ignore
  @include fluid(width, (s: 90px, l: 160px));

  display: block;
  margin: auto;
}

.content__cta__btn {
  flex-shrink: 0;
  margin-left: auto;
}

.content__tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include mq(l) {
    display: flex;
    flex-direction: column;
    grid-area: content;
  }
}

.content__tablist {
  // prettier-ignore
  @include fluid(margin-bottom, (s: 8px, l: 40px));
}

.content__tab-trigger {
  // prettier-ignore
  @include fluid(font-size, (s: 15px, l: 24px));

  position: relative;
  padding: 0 0 0.6rem;
  font-weight: 600;
  line-height: 1.2;
  background: transparent;
  border-width: 0;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #d4107a;
    transform: scaleY(0);
    transform-origin: 0 100%;
  }

  &:not(:last-child) {
    margin-right: 7rem;
  }

  &.active {
    opacity: 1;

    &::after {
      transform: scaleY(1);
    }
  }
}

.content__tab-content {
  overflow: auto;
  margin-bottom: 4rem;
  padding-right: $spacing * 0.5;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $c-anthracite-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c-pink-medium;
  }

  dt,
  dd {
    display: inline;
    line-height: 2rem;
  }

  dt {
    @extend %fw-bold;
  }

  dd {
    margin-left: 0.5em;
  }

  ::v-deep {
    > *:first-child {
      margin-top: 0;
    }
  }

  @include mq(l) {
    height: 43rem;
    max-height: 40vh;
  }
}

.content__grid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include mq(l) {
    display: grid;
    grid-template-areas:
      'visual content'
      'cta    content';
    grid-template-rows: min-content min-content;
    grid-template-columns: 1.8fr 1fr;
    gap: 0 40px;
  }
}

.customer-cta {
  margin-top: auto;
  margin-bottom: 5rem;
  color: currentColor;
  text-align: center;

  @include mq(l) {
    margin-bottom: 0;
    text-align: left;
  }
}

.pictograms {
  display: flex;
}

.pictograms__item {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  margin-left: 1rem;
  opacity: 1;

  &:first-child {
    margin-left: 2rem;
  }

  &__audiodescription {
    width: 3rem;
  }

  @include mq(l) {
    opacity: 0.5;
  }
}
