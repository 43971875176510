
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.voo-link {
  position: relative;
  display: inline-block;
  color: $c-gray-darkest;
  text-decoration: none;
  transition: color 0.3s $ease-out-quad;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: -0.5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-gray-darkest;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -0.5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-pink-medium;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s $ease-out-quad;
  }

  &:hover,
  &:focus {
    color: $c-pink-medium !important;
    background-size: 100% 1px;

    &::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }
}

.link-infos {
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 12px,
      l: 14px,
    )
  );

  margin: $spacing * 1.5 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.9rem;
    fill: $c-blue-light-pale;
    transition: fill 0.3s $ease-out-quad;
  }

  span {
    color: $c-blue-light-pale;
    border-bottom: 1px solid rgba($c-blue-light-pale, 0.15);
    transition: color 0.3s $ease-out-quad;
  }

  &:hover {
    .icon {
      fill: rgba($c-blue-light-pale, 0.6);
    }

    span {
      color: rgba($c-blue-light-pale, 0.6);
    }
  }
}
