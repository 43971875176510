
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































.toast-outer {
  display: inline-block;
  width: 100%;
  will-change: opacity, transform;
}

.toast,
[class*='toast--'] {
  @extend %box-shadow;

  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: $spacing * 0.75 $spacing * 0.5;
  color: $white;
  background-color: $c-blue-dark;
  border-radius: 0.5rem;

  [class*='link'] {
    flex-shrink: 0;
    margin-right: $spacing / 2;
  }

  svg {
    width: 4rem;
    height: 4rem;
    fill: $white;
  }

  @include mq(m) {
    align-items: center;
    padding: $spacing / 2 $spacing;
  }
}

.toast__icon,
.toast__close {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
}

.toast__icon,
.toast__content {
  margin-right: $spacing * 0.5;

  @include mq(m) {
    margin-right: $spacing;
  }
}

.toast__content {
  flex-grow: 1;
  align-self: center;

  @include mq(m) {
    display: flex;
    align-items: center;
  }
}

.toast__content__text {
  flex-grow: 1;
  font-weight: 500;

  b {
    color: $c-pink-medium;
    font-weight: 700;
  }

  [class*='toast--'][class*='--midnight'] & {
    strong: {
      color: $c-pink-medium;
    }
  }

  [class*='toast--'][class*='--light'] & {
    color: black;

    ::v-deep a {
      color: $c-pink-medium;
    }
  }

  @include mq(m) {
    margin-right: 1rem;
  }
}

.toast__content__link {
  margin-top: 1rem;

  @include mq(m) {
    margin-top: 0;
  }
}

.toast__icon {
  [class*='toast--'][class*='--light'] & {
    fill: $c-pink-medium;
  }
}

.toast__close {
  @extend %button-nostyle;

  z-index: 1;
  top: 0;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  border: 0;
  background: transparent;
  fill: $white;

  .icon {
    width: 2.4rem;
    height: 2.4rem;

    [class*='toast--'][class*='--light'] & {
      fill: $black;
    }
  }
}

[class*='toast--'][class*='--important'] {
  background-color: $c-error;
}

[class*='toast--'][class*='--voo'] {
  background-color: $c-pink-medium;
}

[class*='toast--'][class*='--midnight'] {
  background-color: $c-blue-dark;
}

[class*='toast--'][class*='--orange'] {
  background-color: $c-orange;
}

[class*='toast--'][class*='--important'],
[class*='toast--'][class*='--voo'] {
  .toast__content__text b {
    color: $white;
  }
}

[class*='toast--'][class*='--light'] {
  background-color: $white;
}

// Transitions
.toast-leave-active {
  position: absolute;
  transition: opacity 0.3s, transform 0.3s;
}

.toast-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}

.toast-move {
  transition: transform 0.5s;
}

.toast-leave,
.toast-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.toast-enter,
.toast-leave-to {
  opacity: 0;
  transform: translateY(-10%);
}
