
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































.subtitles-pictograms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.5rem;

  @include mq(l) {
    justify-content: flex-start;
  }
}

.content__tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.content__tag-list__item {
  // prettier-ignore
  @include fluid(font-size, (s: 9px, l: 15px));

  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.pictograms {
  display: flex;
}

.pictograms__item {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  margin-left: 1rem;
  opacity: 1;

  &:first-child {
    margin-left: 2rem;
  }

  &__audiodescription {
    width: 3rem;
  }

  @include mq(l) {
    opacity: 0.5;
  }
}
