
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































































.g-product {
  position: relative;
  border: 1px solid $c-gray-lightest;
  border-radius: 1.2rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 10%);
  margin: $spacing * 1.5 0;

  @include mq(l) {
    margin: $spacing * 2.5;
  }
}

.g-product__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: $spacing;

  @include mq(l) {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: $spacing * 1.8 $spacing * 2;
  }
}

.g-product__content__selection {
  min-height: 0;

  ::v-deep .selection__header {
    display: none;
  }
}

.g-product__content__price__label {
  text-align: left;

  &::first-letter {
    text-transform: capitalize;
  }
}

.g-product__content__price {
  justify-content: flex-start;
  margin-left: 0;

  ::v-deep .price__promo {
    position: absolute;
    top: 0;
    left: $spacing;
    border-radius: 0.4rem;
    transform: translateY(-50%);
  }

  ::v-deep .price__main {
    width: auto;
  }

  ::v-deep .price__stack__months {
    @extend %fw-normal;

    color: $c-anthracite-light;
  }

  @include mq(l) {
    ::v-deep .price__promo {
      left: $spacing * 2;
    }
  }
}

.g-product__content__cta {
  width: 100%;
  margin-top: $spacing;

  @include mq(l) {
    width: auto;
    margin-top: 0;
  }
}

.g-product__option {
  @extend %fw-bold;
  @extend %text-center;

  display: flex;
  align-items: center;
  padding: $spacing;
  color: $white;
  font-family: $ff-alt;
  background: $c-bunting;
  border-radius: 0 0 1.2rem 1.2rem;

  @include mq(l) {
    justify-content: center;
  }
}

.g-product__option__picture {
  display: block;
  width: fit-content;
  height: 4rem;
  margin-right: $spacing;

  // filter: brightness(0) invert(1);
}
