
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































.duo {
  margin-top: $spacing * 1.25;

  ::v-deep {
    .overlayer-trigger {
      margin: 0 auto;
    }

    .wysiwyg__content {
      strong {
        font-family: $ff-alt;
        line-height: 1.1;
      }

      p {
        margin: 0;
      }

      p + p {
        margin-top: $spacing * 1.5;
      }

      h2,
      h3,
      h4 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.duo__title {
  margin: 0;
  font-size: 1.8rem;
}

.duo__subtitle {
  font-size: 1.5rem;
}

.duo__items {
  display: flex;
  gap: 2px;
  margin-top: $spacing * 0.5;

  & + & {
    margin-top: 2px;
  }
}

.duo__item {
  @include bg-color;

  display: flex;
  flex-basis: 50%;
  padding: $spacing * 0.75 $spacing;

  &.center {
    @extend %text-center;

    justify-content: center;
    align-items: center;
  }

  &.is-midnight-darkest {
    color: $white;
  }
}

.duo__item__icon {
  width: 3rem;
  height: 3rem;
  fill: $c-pink-medium;

  &.green {
    fill: $c-green-light;
  }

  &.pink {
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-pink-medium;
  }

  &.icon-shared-icons-crossrounded {
    padding: 0.3rem;
  }
}
