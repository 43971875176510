
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































.root {
  position: relative;

  // mobile promo optin success page has little content and its height is likely lower than 100vh
  // in this case we want the footer to be visible without having to scroll.
  &:not(.page-template-mobile-promo-optin) {
    min-height: 100vh;
  }
}
