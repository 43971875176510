
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        















































































.ui-side-panel-outer {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: rgba($black, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s $ease-in-quad 0.4s;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.35s $ease-out-quad;
  }
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  @include mq(m) {
    width: auto;
    min-width: 90vw;
  }
  @include mq(l) {
    min-width: 90rem;
  }
}

.panel-inner {
  overflow-x: hidden;
  height: 100%;
}

.panel__component {
  min-width: 100%;
  min-height: 100vh;
  padding: 5rem 2rem 0;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: $white;
  background-color: $white;

  &.panel-default {
    padding-inline: inherit;
  }

  &.panel-wysiwyg {
    padding-top: $spacing * 3;
    width: 100%;
    max-width: 70rem;

    @include mq('s') {
      padding-inline: $spacing * 3;
    }
  }

  ::v-deep {
    table {
      border: 1px solid $c-blue-lightest;

      td {
        padding: 1rem;
        border: 1px solid $c-blue-lightest;
      }
    }
  }

  @include mq($until: m) {
    padding-bottom: $spacing * 8 !important;
  }
}

.close {
  @extend %box-shadow;

  position: absolute;
  z-index: 10;
  top: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0;
  line-height: 1;
  background-color: $c-light;
  border: 0;
  border-radius: 50%;
  opacity: 1;
  transition: background-color 0.25s ease, opacity 0.25s ease 0.75s;

  svg {
    width: 2.6rem;
    height: 2.6rem;
    fill: $c-pink-medium;
    transition: fill 0.25s ease;
  }

  &:hover {
    background-color: $c-pink-medium;

    svg {
      fill: $c-light;
    }
  }

  @include mq(m) {
    top: 2.5rem;
    right: 3rem;
  }
}

// Transitions
.panel-enter-active {
  transition: transform 0.25s ease-out;
}

.panel-leave-active {
  transition: transform 0.15s ease-in;
}

.panel-leave,
.panel-enter-to {
  transform: translateX(0);
}

.panel-enter,
.panel-leave-to {
  transform: translateX(100%);
}
