/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */

.voo-menu__list {
  @extend %list-nostyle;

  @include mq($until: m) {
    margin-top: 7vh !important;
    // margin-left: $spacing * 1.5 !important;
  }

  @include mq(m, l) {
    margin-top: 7vh !important;
    margin-left: $spacing * 0.75 !important;
  }

  @include mq('l') {
    display: flex;
    align-items: center;
  }
}

.voo-menu__list__item,
[class*='voo-menu__list__item--'] {
  position: relative;
  // Modifiers
  &[class*='--mobile'] {
    @include mq(l) {
      display: none;
    }
  }

  > a {
    @extend %fw-bold;

    position: relative;
    color: $c-blue-dark;
    text-decoration: none;
    transition: color 0.1s $ease-out;

    &:hover {
      color: $c-pink-medium !important;
    }

    // .voo-header.is-light & {
    //   @include mq(l) {
    //     color: $white;
    //   }
    // }

    // [data-template='blog'] &,
    [data-scheme='dark'] &,
    [data-scheme='transparent'] &,
    [data-template='employee-advocacy'] &,
    [data-template='blog-by'] &,
    .voo-app--be.chrome-light & {
      @include mq(l) {
        color: $white !important;

        &.router-link-active {
          color: $c-pink-medium !important;
        }

        &:hover {
          color: $c-pink-medium !important;
        }
      }
    }

    [data-scheme='transparent'] & {
      .voo-header:not(.menu-open) {
        color: $white !important;
        text-shadow: 0 0 5px #0000003b;

        &:hover {
          color: $c-pink-medium !important;
        }
      }
    }

    &.router-link-exact-active {
      pointer-events: none;
    }

    &.router-link-active {
      color: $c-pink-medium !important;
    }

    &.is-page {
      color: $c-blue-dark !important;

      &::after {
        content: none;
      }
    }
  }

  @include mq($until: 'l') {
    font-size: 2.4rem;
  }

  @include mq('l') {
    & + & {
      margin-left: $spacing * 3.5;
    }
  }
}

.voo-menu__list__item__link {
  @include mq($until: l) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh 4vh;
  }
}

.voo-menu__list__item__arrow {
  fill: $c-blue-dark;
  width: 1.5rem;
  height: 1.5rem;

  @include mq('l') {
    display: none;
  }
}

// Display submenu on activ parent
.voo-menu__list__item__submenu {
  // display: none;
  opacity: 0;
  pointer-events: none;
  // transition: opacity 0.2s 2s ease-out;

  .router-link-active + & {
    // display: block;
    opacity: 1;
    pointer-events: initial;
    transition: opacity 0.2s 1s ease-out;
  }
}
