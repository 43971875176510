
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



























































































.sticky-content-outer {
  position: relative;
  display: block;
}

.sticky-content {
  position: relative;

  .is-cropped & {
    overflow: hidden;
  }
}

.sticky-content__background {
  @include get-all-space;

  overflow: hidden;
  background-color: #111019;
  border-radius: 8px 0 0 8px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    transform-origin: 50% 50%;
  }

  &::before {
    top: 25%;
    left: -25%;
    width: 100rem;
    height: 25rem;
    background-color: #312d72;
    transform: rotate(-60deg) translateY(-50%);
    border-radius: 15rem;
    filter: blur(100px);
    opacity: 0.5;
  }

  &::after {
    top: 60%;
    left: 40%;
    width: 30rem;
    height: 10rem;
    background-color: $c-mandarine;
    transform: rotate(-60deg) translateY(-50%);
    border-radius: 15rem;
    filter: blur(50px);
    opacity: 0.5;
  }
}

.sticky-content-inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8rem $spacing $spacing;
  color: $c-light;
  text-align: center;

  .is-cropped & {
    padding-top: $spacing;
  }

  @include mq(xs) {
    padding: 16rem $spacing * 2 $spacing * 2;
  }
}

.sticky-content__picture {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: $spacing * -5 auto 0;
  padding: 0 $spacing * 2;
  line-height: 0;
  border-radius: 8px 0 0;

  .is-cropped & {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    height: 14rem;
    margin: 0;
    padding: 0;

    @include mq(xs) {
      height: 20rem;
    }
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;

  .is-cropped & {
    transform: none;
    object-fit: cover;
  }

  .sticky-content:hover & {
    transform: scale(1.05);
  }
}

.sticky-content__title {
  margin-top: 0;
  margin-bottom: $spacing;
  font-size: 2.8rem;

  @include mq(xs) {
    font-size: 3.6rem;
  }
}

.sticky-content__label {
  margin: 0 auto $spacing;
  padding: 0.5rem 1.2rem;
  font-family: $ff-alt;
  font-size: 1.3rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.5px;
  border-radius: 3rem;

  .is-pink & {
    background-color: $c-pink-medium;
  }

  .is-orange & {
    background-color: $c-mandarine;
  }

  .is-cyan & {
    background-color: $c-cyan-contrast;
  }

  @include mq(xs) {
    padding: 0.7rem 1.3rem;
  }
}

.sticky-content__cta {
  position: relative;
  padding-bottom: 4px;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;

  &::before {
    .is-pink & {
      /* stylelint-disable-next-line declaration-no-important */
      background: $c-pink-medium !important;
    }

    .is-orange & {
      /* stylelint-disable-next-line declaration-no-important */
      background: $c-mandarine !important;
    }

    .is-cyan & {
      /* stylelint-disable-next-line declaration-no-important */
      background: $c-cyan-contrast !important;
    }
  }

  .sticky-content:hover & {
    background-size: 100% 1px;

    .is-pink & {
      /* stylelint-disable-next-line declaration-no-important */
      color: $c-pink-medium !important;
    }

    .is-orange & {
      /* stylelint-disable-next-line declaration-no-important */
      color: $c-mandarine !important;
    }

    .is-cyan & {
      /* stylelint-disable-next-line declaration-no-important */
      color: $c-cyan-contrast !important;
    }

    &::before {
      transform-origin: left;
      transform: scaleX(1);
    }

    &::after {
      .is-pink & {
        /* stylelint-disable-next-line declaration-no-important */
        background: $c-pink-medium !important;
      }

      .is-orange & {
        /* stylelint-disable-next-line declaration-no-important */
        background: $c-mandarine !important;
      }

      .is-cyan & {
        /* stylelint-disable-next-line declaration-no-important */
        background: $c-cyan-contrast !important;
      }
    }
  }

  @include mq(xs) {
    font-size: 2.2rem;
  }
}

.sticky-content__link {
  @include get-all-space;

  z-index: 5;
}

::v-deep .sticky-content__countdown {
  width: auto;
  margin-bottom: $spacing;
  background: none;
  border-radius: 0;

  .countdown__label {
    display: none;
  }

  .countdown__table-outer {
    padding: 0;
    color: $white;
    border: 0;
    border-radius: 0;
  }

  .countdown__table__row__column {
    width: 4rem;
    margin: 0 0.25rem;
    padding-right: 0.5rem;
    font-size: 2rem;
    font-weight: 700;

    &::before {
      right: -2px;
    }

    &:last-child {
      margin-right: 0;
    }

    @include mq(xs) {
      width: 6rem;
      font-size: 3rem;
    }

    @include mq('s') {
      margin: 0 0.5rem;
      padding-right: 1rem;
    }

    @include mq('m') {
      padding-right: 1.25rem;
    }
  }

  .countdown__table__row__column__label {
    font-size: 1.1rem;

    @include mq('l') {
      font-size: 1.3rem;
    }
  }

  @include mq('l') {
    margin-bottom: $spacing;
  }
}
