
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































































































.help,
[class*='help--'] {
  @include bg-color;

  position: relative;
  padding: $spacing * 2.5 0 $spacing * 3;

  &::before {
    content: '';
    position: absolute;
    right: -$spacing;
    bottom: 0;
    display: block;
    width: 100vw;
    height: 100%;
    background-color: $c-light-gray;

    @include mq(m) {
      right: -$spacing * 3.5;
    }

    @include mq(l) {
      right: 0;
    }
  }

  @include mq(m) {
    padding: $spacing * 3.5 0 $spacing * 4;
  }

  @include mq(l) {
    width: col(7);
    padding: $spacing * 5 0 $spacing * 6;
    padding-right: col(1);
  }
}

.help__icon {
  position: relative;
  z-index: 1;
  width: 3.6rem;
  height: 3.6rem;
  fill: $c-pink-medium;
}

.help__title {
  position: relative;
  z-index: 1;
  margin-top: $spacing / 2;
}

.help__dropdown-outer {
  position: relative;
  z-index: 20;
  height: 5rem;
  margin-bottom: $spacing * 2;
}

.help__dropdown {
  @extend %box-shadow;

  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  background-color: $white;
  border-radius: 4px;
}

.help__dropdown__item,
[class*='help__dropdown__item--'] {
  font-size: 0.9em;
  line-height: 3.2rem;
  text-decoration: none;

  &:first-child {
    padding: 0 $spacing;
    font-size: 1em;
    line-height: 5rem;
  }
}

.help__dropdown__item__link,
[class*='help__dropdown__item__link--'] {
  display: block;
  padding: $spacing / 2 $spacing;
  line-height: 3.2rem;
  text-decoration: none;
  transition: background-color 0.3s $ease-out-quad;

  &:hover {
    background-color: darken($white, 5%);
  }
}

.help__dropdown__icon {
  position: absolute;
  top: $spacing / 1.2;
  right: $spacing;
  width: 1.8rem;
  height: 1.8rem;
  fill: $c-pink-medium;
  pointer-events: none;
  transform-origin: center center;
  transition: transform 0.2s ease-out;

  .is-open & {
    transform: rotate(180deg);
  }
}

.help__link {
  position: relative;
  color: $c-pink-medium;
  text-decoration: none;
  background-image: linear-gradient($c-pink-medium, $c-pink-medium);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size 0.3s $ease-out-quad;

  &:hover,
  &:focus {
    background-position: 100% 100%;
    background-size: 0% 1px;
  }
}

// Modifier
[class*='help__dropdown__item--'][class*='--trigger'] {
  pointer-events: none;
}
