
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































































































































































































































































$content-max-width: 1125px;

.spinner-container {
  display: grid;
  padding: 2rem;
  place-items: center;
}

.spinner {
  display: block;
}

.popup-pannel__root {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  // Center popuppanel content without transform that might mess up with entrance animation
  display: grid;
  width: 100%;
  height: 100vh;
  place-items: center;
}

.popup-pannel__backdrop {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;

  @include mq($until: l) {
    display: none;
  }
}

.popup-pannel__content-wrapper {
  position: relative;
  z-index: 2; // Higher than backdrop
  overflow: auto;
  width: 100vw;
  height: 100vh;
  color: $white;

  @include mq(l) {
    max-width: $content-max-width;
    height: auto;
    max-height: 100vh;
    border-radius: 1rem;

    .is-small & {
      max-width: 60rem;
    }
  }
}

.popup-pannel__content-wrapper__inner {
  position: relative;
  background: $c-promo-card;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    opacity: 0.8;
  }

  &::before {
    background: radial-gradient(
      70.77% 37.46% at 121.79% 106.52%,
      rgba(12, 186, 186, 0.42) 0%,
      rgba(12, 186, 186, 0.14) 49.3%,
      rgba(12, 186, 186, 0) 100%
    );
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      radial-gradient(
        143.46% 103.5% at -2.56% 30.21%,
        rgba(12, 186, 186, 0.42) 0%,
        rgba(12, 186, 186, 0.14) 49.3%,
        rgba(12, 186, 186, 0) 100%
      );
  }
}
