
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































































































































































.sticky-banner,
[class*='sticky-banner--'] {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;

  @include mq(l) {
    z-index: 100;
    top: auto;
    background: $c-midnight-darker;
    box-shadow: 0 0 4rem 0 rgba($white, 0.2);
    opacity: 0;
    transition: $ease-out-quad 0.5s;
    transition-property: transform opacity;
    transform: translateY(100%);

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.sticky-banner__close {
  position: absolute;
  z-index: 1;
  top: -5rem;
  right: 0;
  display: none;
  padding: 1rem;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.4rem;
  text-transform: uppercase;
  background: transparent;
  border: 0;

  .is-visible & {
    display: flex;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 1rem;
    fill: $white;
  }

  @include mq(l) {
    top: 0;
    right: 1rem;
    padding: 2rem;
    font-weight: 900;
  }

  @include mq(xxl) {
    right: -4rem;
  }
}

.sticky-banner__bg {
  @include get-all-space;

  background: rgba($c-blue-dark, 0.8);

  @include mq(l) {
    display: none;
  }
}

.sticky-banner__content {
  position: relative;
  z-index: 1;
  max-width: 128rem;
  margin: 0 2rem;

  @include mq(l) {
    width: 100%;
    margin: 0;
  }
}
