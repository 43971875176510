
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






















































































































.countdown,
[class*='countdown--'] {
  position: relative;
  width: 100%;
  max-width: 39rem;
  border-radius: 1rem;

  &[data-status='finished'] {
    display: none;
  }
}

.countdown-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  color: $c-blue-dark;
}

.countdown__content {
  max-width: 20rem;
  font-family: $ff-alt;
  font-size: 1.6rem;
  line-height: 1.3;

  @include mq(l) {
    max-width: 25rem;
    font-size: 2rem;
  }
}
