
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































































































$w: 1.3rem;
$h: 1.1rem;
$s: 1.5;
$p: 0.6rem;
$bg: $c-gray-darkest;
$c: $white;

.env-switcher,
[class*='env-switcher--'] {
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 $p 0 0;
  color: $c;
  background-color: $bg;
  transform: translateX(calc(100% - #{(($w * $s) + (2 * $p))}));

  &.is-open {
    /* stylelint-disable-next-line declaration-no-important */
    transform: translateX(0) !important;
  }
}

[class*='env-switcher--'][class*='--left'] {
  right: auto;
  left: 0;
  flex-direction: row-reverse;
  padding: 0 0 0 $p;
  transform: translateX(calc(-100% + #{(($w * $s) + (2 * $p))}));
}

.env-switcher__confirm,
.env-switcher__toggler {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $p;
  color: $c;
  line-height: 1;
  background-color: $bg;
  border: none;
  fill: currentcolor;

  svg {
    width: $w * $s;
    height: $h * $s;
    fill: inherit;
  }

  &:hover {
    opacity: 0.9;
  }
}

.env-switcher__content {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $p;
  margin: 0;
  padding: $p;
  border: 0 dotted $c;
  border-width: 0 0 0 1px;

  [class*='env-switcher--'][class*='--left'] & {
    border-width: 0 1px 0 0;
  }
}

.env-switcher__content__item__label,
.env-switcher__content__item__select {
  font-size: 0.8em;
}

.env-switcher__content__item__select {
  color: $c;
  background-color: $bg;
  border: 1px solid $c;
  border-radius: 4px;
}
