
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































.relay,
[class*='relay--'] {
  position: relative;
  border-top: 1px solid $c-light-gray;
}

.relay-inner {
  display: flex;
  flex-direction: column;

  @include mq('l') {
    flex-direction: row;
  }
}
