
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























.disclaimer {
  font-size: 14px;
  font-style: italic;
  opacity: 0.8;

  @include mq($until: s) {
    margin-top: 6rem;
    font-size: 13px;
  }
}
