
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































































































.toaster,
[class*='toaster--'] {
  position: absolute;
  z-index: 200;
  top: 8rem;
  left: 50%;
  width: 95%;
  max-width: 80rem;
  transform: translateX(-50%);
  transition: all 0.35s ease-out;

  &.is-waiting {
    opacity: 0;
    transform: translate(-50%, -2rem);
  }

  > * {
    margin-bottom: $spacing * 0.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq(l) {
    top: 18rem;
  }
}
