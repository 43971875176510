
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































































































.flickity-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.6rem;
  height: 4.6rem;
  padding: 0;
  background: transparent;
  border: 2px solid $c-anthracite-light;
  border-radius: 50%;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: $c-anthracite-light;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    background: $c-anthracite-light;

    svg {
      fill: $white;
    }
  }

  &:disabled {
    // display: none;
    opacity: 0;
  }
}

.generic-slider,
[class*='generic-slider--'] {
  position: relative;
  width: 100%;
  height: auto;
}

.generic-slider {
  position: relative;
  width: 100%;
  height: auto;
}

.generic-slider__slides,
.generic-slider__slides__item {
  width: 100%;
  height: auto;
}

.generic-slider__slides {
  outline: none;

  &::after {
    content: 'flickity';
    display: none;
  }

  &.is-disabled {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &::after {
      content: '';
    }
  }

  &.no-arrows {
    .flickity-button {
      opacity: 0;
    }
  }
}

.flickity-viewport {
  margin-bottom: $spacing * 2;
}

.flickity-page-dots {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
  align-items: center;

  .dot {
    width: 8px;
    height: 8px;
    margin: 0 0.35rem;
    background-color: $gray-lightest;
    border-radius: 50%;
    transition: background 0.25s ease;
    cursor: pointer;
    opacity: 0.3;
    &.is-selected,
    &:hover {
      opacity: 1;
      background-color: $gray-lighter;
    }
  }
}
