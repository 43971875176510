
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































































































































































.generic-panel,
[class*='generic-panel--'] {
  @include get-all-space;

  position: fixed;
  z-index: $z-index-header;
  display: flex;
  justify-content: flex-end;
}

.generic-panel__bg {
  @include get-all-space;

  content: '';
  z-index: 1;
  background: rgba($black, 0.6);
}

.generic-panel__content {
  position: relative;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;
  min-height: 100vh;
  /* stylelint-disable-next-line value-no-vendor-prefix, declaration-block-no-duplicate-properties */
  min-height: -webkit-fill-available; // fix toolbar overlap in Safari
  transform: translateX(100%);
}

.generic-panel__close {
  @extend %box-shadow;

  position: absolute;
  z-index: 10;
  top: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0;
  line-height: 1;
  background-color: $c-light;
  border: 0;
  border-radius: 50%;
  opacity: 0;
  transition: background-color 0.25s ease, opacity 0.25s ease 0.75s;

  svg {
    width: 2.6rem;
    height: 2.6rem;
    fill: $c-pink-medium;
    transition: fill 0.25s ease;
  }

  &:hover {
    background-color: $c-pink-medium;

    svg {
      fill: $c-light;
    }
  }

  &.is-dark {
    svg {
      fill: $black;
    }

    &:hover {
      background-color: $black;

      svg {
        fill: $c-light;
      }
    }
  }

  &.is-visible {
    opacity: 1;
  }

  @include mq(m) {
    top: 2.5rem;
    right: 3rem;
  }
}
