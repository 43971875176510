
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































































































































































































































































































































































































































































































































































































// .page-change-enter-active,
// .page-change-leave-active {
//   transition: opacity 0.3s;
// }

// .page-change-enter,
// .page-change-leave-to {
//   opacity: 0;
// }

body[data-template='news'] .voo-app--be {
  overflow: visible;

  @include mq($until: m) {
    overflow: hidden;
  }
}
