
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































.cta {
  line-height: 1;
}

.cta__order {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem;
}

.cta__number {
  display: block;
  margin-top: 1rem;
  color: $c-blue-light-pale;
  font-size: 1.3rem;
  text-align: center;

  a {
    @extend %fw-bold;

    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
