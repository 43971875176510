
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























































































































































































































.card {
  position: relative;

  &.has-promo {
    ::v-deep {
      .transitions-number__current,
      .price__stack__decimal {
        color: $c-mandarine;
      }
    }

    .price__original {
      margin-right: revert;
      color: revert;
      font-size: revert;
    }
  }

  &.is-zuny {
    ::v-deep {
      .transitions-number__current,
      .price__stack__decimal,
      .price__main {
        font-family: $ff-zuny;
        color: $c-midnight-darkest;
      }
    }
  }
}

.product-card__rgus-labels {
  display: block;
  margin: 0 0 2rem;
  font-family: $ff-alt;
  font-size: 1.1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.advantage {
  position: absolute;
  top: calc(100% - 1.5rem);
}

.product-card__compare {
  @extend %fw-bold;

  display: none;
  width: 20rem;
  margin: 0 auto;
  padding: 1.5rem 0;
  color: $c-bunting;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: $c-light-gray;
  border-radius: 0 0 12px 12px;

  &:hover {
    background: lighten($c-light-gray, 3);
  }

  [data-template='offers-packs'] & {
    display: block;
  }
}

.product-card__terms-link {
  display: none;
  position: relative;
  z-index: 10;
  margin-top: $spacing * 0.5;
  color: $c-gray;
  font-size: 1.2rem;
  text-align: center;
}

.product-card__zuny {
  margin: $spacing * 2 0;
  font-size: 1.4rem;
  text-align: center;
  transform: scale(0);
  animation: zuny 0.25s 0.5s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .is-left &,
  .is-right & {
    display: none;
  }
}

.product-card__zuny-inner {
  position: relative;
  display: inline-block;

  ::v-deep {
    img {
      margin-left: 0.5rem;
    }
  }
}

.product-card__zuny__link {
  @include get-all-space;
}

.product-card__label {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(-1.5rem, -50%);

  &::after {
    position: absolute;
    top: calc(100% - 2px);
    left: 1px;
    width: 1.5rem;
    height: 1.5rem;
    background-color: darken($c-pink-medium, 30%);
    clip-path: polygon(0% 0%, 100% 0, 100% 75%);
    content: '';
  }

  span {
    position: relative;
    z-index: 2;
    display: block;
    padding: 8px 14px;
    color: $white;
    font-size: 1.2rem;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: $c-pink-medium;
    border-radius: 5px;
  }

  .is-zuny & {
    &::after {
      background-color: darken($c-zuny-lighter, 30%);
    }

    span {
      color: $black;
      background-color: $c-zuny-lighter;
    }
  }
}

.product-card__pricing {
  margin-bottom: $spacing * 1.5;

  ::v-deep {
    .price-htva {
      text-align: center;
    }
  }
}

::v-deep {
  .price__main,
  .price__stack__decimal,
  .price__stack__months {
    color: $c-anthracite-light;
  }

  .selection__logo__img {
    .is-zuny & {
      height: 1.5rem;
    }
  }
}

@keyframes zuny {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
