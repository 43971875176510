
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
















































.header-dark {
  position: sticky;
  z-index: 12;
  top: 0;

  @include mq($until: m) {
    top: $spacing * 4;
  }
}
.header-dark__items {
  display: flex;
  gap: 2px;
  margin-top: $spacing * 0.5;

  & + & {
    margin-top: 2px;
  }
}

.header-dark__item {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: $spacing * 0.75 0;
  color: $white;
  font-family: $ff-alt;
  background-color: $c-anthracite-light;
  border-radius: 0.5rem 0.5rem 0 0;

  &.is-midnight-darkest {
    background-color: $c-midnight-darkest;
  }

  &.is-magenta {
    background-color: $c-pink-medium;
  }

  &.is-cyan {
    color: $black;
    background-color: $c-zuny-lighter;
  }
}

.header-dark__item__headline {
  @extend %text-uppercase;

  font-size: 1.1rem;
  letter-spacing: 1px;
}

.header-dark__item__text {
  font-size: 2.1rem;
  font-weight: 700;
}
